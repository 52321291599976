
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmFieldsContainerSocialMedia from '@/components/shared/tmFieldsContainer/TmFieldsContainerSocialMedia.vue'
import {
  contactListsOptions, contactsStatusOptions, assigneeOptions, contactsFieldsList, organizationsOptions, tagsOptions
} from '@/definitions/_general/_data/optionsList'
import { capitalize } from '@/services/utils'
import type { ListChip } from '@/definitions/shared/types'

export default defineComponent({
  name: 'ContactModal',
  components: {
    TmFormLine,
    TmButton,
    TmModal,
    TmFieldsContainerSocialMedia,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Add contact',
    },
    createAnother: {
      type: Boolean,
      default: true,
    },
    btnText: {
      type: String,
      default: 'Add contact',
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    company: {
      type: String,
      default: '',
    },
    errorState: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const firstNameVal = ref(props.firstName)
    const lastNameVal = ref(props.lastName)
    const phone = ref(props.errorState ? '' : '+1 (776) 998-2246')
    const emailValue = ref(props.email)
    const lists = ref<ListChip[]>([contactListsOptions[0]])
    const listsOptions = ref<ListChip[]>([...contactListsOptions])
    const contactsStatus = ref(contactsStatusOptions[0])
    const assigneeValue = ref(assigneeOptions[0])
    const organizationValue = ref(null)
    const jobTitle = ref('Manager')
    const tagsValue = ref(tagsOptions.slice(0, 2))
    const socialMedia = ref(['facebook.com/textmagic', 'vk.com/textmagic'])
    const moreOpened = ref(false)

    return {
      capitalize,
      firstNameVal,
      lastNameVal,
      phone,
      emailValue,
      jobTitle,
      lists,
      listsOptions,
      contactsStatus,
      contactsStatusOptions,
      assigneeValue,
      assigneeOptions,
      organizationValue,
      organizationsOptions,
      tagsValue,
      tagsOptions,
      contactsFieldsList,
      socialMedia,
      moreOpened,
    }
  },
})
